import React from "react";

const faqs = [
    {
        question: "¿Necesito registrarme?",
        answer: "Sí, el registro es indispensable para garantizar tu cupo en el evento y permitir una comunicación efectiva con las instituciones educativas de tu interés después de la feria."
    },
    {
        question: "¿Tiene algún costo la entrada a la Expo?",
        answer: "El evento es completamente gratuito. No hay ningún costo asociado, ni para el ingreso ni durante tu participación en la feria."
    },
    {
        question: "¿Puedo asistir con mi pareja, padres, hermanos o amigos?",
        answer: "¡Claro que sí! Te invitamos a venir acompañado. Solo ten en cuenta que cada persona que desee recibir información personalizada de las instituciones necesitará registrarse individualmente."
    },
    {
        question: "¿Cómo sé si este evento es para mí?",
        answer: "Si sueñas con estudiar en Canadá y estás buscando una experiencia transformadora en uno de los países con mejor calidad de vida del mundo, ¡este evento es perfecto para ti!"
    },
    {
        question: "¿Cómo puedo participar en el sorteo del boleto aéreo a Canadá?",
        answer: "Al completar tu visita en el evento, recibirás un boleto para participar en este emocionante sorteo. Solo necesitas llenar tus datos y depositarlo en la urna ubicada en la salida. ¡No pierdas esta increíble oportunidad!"
    },
    {
        question: "¿Qué instituciones participan en esta Expo?",
        answer: "Contaremos con la presencia de prestigiosas instituciones educativas ubicadas en provincias estratégicas de Canadá, caracterizadas por su accesible costo de vida y sus atractivos programas de residencia permanente."
    },
    {
        question: "¿Qué debo llevar el día del evento?",
        answer: "Solo necesitas dos cosas: tu documento de identificación oficial y haber completado tu registro previo al evento."
    },
    {
        question: "¿Dónde será el evento?",
        answer: "Estamos finalizando los detalles de la ubicación. ¡Muy pronto compartiremos esta información!"
    }
]

function Faqs() {
    return (
        <>
            <div className="accordion" id="faqsAccordion">
                {faqs.map((faq, index) => (
                    <div className="accordion-item" key={index}>
                        <h3 className="accordion-header" id={`heading${index}`}>
                            <button
                                className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded={index === 0 ? 'true' : 'false'}
                                aria-controls={`collapse${index}`}
                            >
                                {faq.question}
                            </button>
                        </h3>
                        <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#faqsAccordion"
                        >
                            <div className="accordion-body">
                                {faq.answer}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
}

export default Faqs;