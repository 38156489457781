'use client'

import React, { useState, useEffect, useRef } from 'react'

export default function Timeline() {
    const [scrollProgress, setScrollProgress] = useState(0)
    const timelineRef = useRef(null) // Fixed TypeScript syntax

    const timelineData = [
        {
            title: "Inscríbete",
            content: "Regístrate y asegura tu lugar en este evento exclusivo para conocer cómo estudiar y vivir en Canadá. Nuestro formulario de registro es rápido y sencillo, y te permitirá reservar tu espacio sin costo.",
            image: "/assets/images/register.jpg" // Use actual image path
        },
        {
            title: "Recibe Confirmación",
            content: "Revisa tu correo y confirma tu asistencia. ¡Ya estás un paso más cerca de hacer realidad tus planes! Te enviaremos todos los detalles del evento, incluyendo fecha, hora y ubicación exacta.",
            image: "/assets/images/email.jpg"
        },
        {
            title: "Asiste al Evento",
            content: "Conéctate con expertos, obtén toda la información que necesitas y resuelve tus dudas en persona. Conocerás los requisitos de visa, opciones de estudio, costos de vida, oportunidades laborales y consejos prácticos para tu adaptación.",
            image: "/assets/images/event.jpg"
        },
        {
            title: "Inicia tu camino hacia Canadá",
            content: "Con los conocimientos y contactos del evento, empieza a planificar tu futuro en Canadá. Tendrás acceso a recursos exclusivos, guías paso a paso y asesoría personalizada para hacer tu transición más fácil y exitosa.",
            image: "/assets/images/flag.jpg"
        }
    ]

    // Optimized scroll handler with throttling
    useEffect(() => {
        let ticking = false

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    if (timelineRef.current) {
                        const timelineTop = timelineRef.current.offsetTop
                        const timelineHeight = timelineRef.current.offsetHeight
                        const scrollPosition = window.scrollY - timelineTop
                        const viewportHeight = window.innerHeight

                        const progress = scrollPosition > 0
                            ? Math.min(scrollPosition / (timelineHeight - viewportHeight), 1)
                            : 0

                        setScrollProgress(progress)
                    }
                    ticking = false
                })
                ticking = true
            }
        }

        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    // Extracted timeline line styles for better readability
    const lineStyles = {
        base: {
            left: '50%',
            top: '0',
            bottom: '0',
            width: '4px', // Increased from 2px to 6px
            backgroundColor: '#E0E0E0',
            transform: 'translateX(-50%)', // Center the line
            height: 'calc(100% + 165px)',
        },
        progress: {
            left: '0',
            top: '0',
            width: '100%',
            backgroundColor: '#b00000',
            height: `${scrollProgress * 100}%`,
            transition: 'height 0.1s ease-out'
        }
    }

    return (
        <div className="block" ref={timelineRef}>
            <div className="timeline position-relative">
                <div className="position-absolute" style={lineStyles.base}>
                    <div className="position-absolute" style={lineStyles.progress} />
                </div>

                {timelineData.map((item, index) => (
                    <div
                        key={index}
                        className={`row align-items-center mb-5 timeline-item-content ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}
                    >
                        <div className="col-md-6 mb-4 mb-md-0">
                            <img
                                src={item.image}
                                alt={item.title}
                                className="img-fluid"
                                loading="lazy"
                            />
                        </div>
                        <div className="col-md-6 position-relative">
                            <div
                                className="position-absolute"
                                style={{
                                    top: '50%',
                                    [index % 2 === 0 ? 'left' : 'right']: '-9px',
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: '#b00000',
                                    borderRadius: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 2
                                }}
                            />
                            <div className={`p-4 timeline-item-content-text ${index % 2 === 0 ? 'text-start' : 'text-end'}`}>
                                <h2 className="text-danger">{item.title}</h2>
                                <p className="">{item.content}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}