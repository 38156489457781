import React from "react";

const locations = [
    {
        title: "CDMX México",
        icon: "🇲🇽",
        dates: "Febrero 15, 2025",
        times: "11:00AM - 7:00PM",
        image: "/assets/images/cdmx.jpg"
    },
    {
        title: "Guadalajara México",
        icon: "🇲🇽",
        dates: "Febrero 18, 2025",
        times: "3:00PM - 7:00PM",
        image: "/assets/images/guadalajara.jpg"
    },
    {
        title: "Medellín Colombia",
        icon: "🇨🇴",
        dates: "Febrero 20, 2025",
        times: "3:00PM - 7:00PM",
        image: "/assets/images/medellin.jpg"
    },
    {
        title: "Bogotá Colombia",
        icon: "🇨🇴",
        dates: "Febrero 22, 2025",
        times: "11:00AM - 7:00PM",
        image: "/assets/images/bogota.jpg"
    }
]

const Locations = ({ ...props }) => {
    return (
        <>
            {locations.map((location, index) => (
                <div className="location-item" key={index}>
                    <span className="icon">{location.icon}</span>
                    <figure
                        className="nostatic"
                        style={{ backgroundImage: `url(${location.image})` }}
                    ></figure>
                    <h4>{location.title}</h4>
                    <p>{location.dates}</p>
                    <p>{location.times}</p>
                </div>
            ))}
        </>
    );
};

export default Locations;