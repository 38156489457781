import React from "react";
import Locations from "../../components/Locations";
import Timeline from "../../components/Timeline.js";
import Faqs from "../../components/Faqs";
import SEO from "../../components/SEO";


import { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

function RegisterModal({ show, handleClose }) {
    const [formData, setFormData] = useState({
        nombre: '',
        telefono: '',
        email: '',
        ciudad: '',
        identificacion: '',
        validation: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setIsSubmitting(true);

        try {
            const response = await fetch('/api/register.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Error al enviar el formulario');
            }

            setSuccess(data.message);
            setFormData({
                nombre: '',
                telefono: '',
                email: '',
                ciudad: '',
                identificacion: '',
                validation: ''
            });
            // Removed auto-close timeout to only close on button click

        } catch (err) {
            setError(err.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Regístrate hoy y da el primer paso hacia tu futuro en Canadá</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre completo</Form.Label>
                        <Form.Control
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Identificación</Form.Label>
                        <Form.Control
                            type="text"
                            name="identificacion"
                            value={formData.identificacion}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                            type="tel"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Correo electrónico</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Ciudad a donde asistirás</Form.Label>
                        <Form.Control
                            type="text"
                            name="ciudad"
                            value={formData.ciudad}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Validación de Seguridad</Form.Label>
                        <Form.Control
                            type="text"
                            name="validation"
                            placeholder="¿Cuánto es 2 + 2?"
                            value={formData.validation}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                    {success && (
                        <div className="alert alert-success" role="alert">
                            {success}
                        </div>
                    )}
                    {isSubmitting && (
                        <div className="alert alert-info" role="alert">
                            Estamos procesando su registro, creando su código QR y enviándole un correo con la información. Un momento por favor...
                        </div>
                    )}

                    <Button
                        variant="primary"
                        type="submit"
                        className="button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Un momento...' : 'Enviar Registro'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
function Home() {
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handleClose = () => setShowRegisterModal(false);

    const images = [
        '/assets/images/hero.jpg',
        '/assets/images/hero2.jpg',
        '/assets/images/hero3.jpg',
        '/assets/images/hero4.jpg'
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <RegisterModal
                show={showRegisterModal}
                handleClose={handleClose}
            />

            <SEO
                title="Expo para Canadá | Da el Primer Paso Hacia una Experiencia Transformadora de Estudio en Canadá"
                description="No es una expo cualquiera: descubre cómo estudiar en Canadá de forma inteligente y con un plan claro para alcanzar tus metas."
                keywords="expo para canada, estudiar en canada, oportunidades educativas, residencia permanente, boleto aereo a canada"
                ogImage="/assets/images/og.jpg"
                favicon="/assets/images/favicon-32x32.png"
                appleFavicon="/assets/images/apple-touch-icon.png"
            />

            <div style={{
                backgroundImage: `url(${images[currentImageIndex]})`,
                transition: 'background-image 1s ease-in-out'
            }} className="hero nostatic">
                <div className="container">
                    <span style={{ color: '#fff', opacity: 1, fontWeight: '600', fontSize: '1.5rem' }} className="pretitle">Expo para Canadá</span>
                    <h1>
                        Inicia Tu Aventura en Canadá: <span>Una Experiencia que Cambiará Tu Vida</span>
                    </h1>
                    <p className="lead">
                        Aquí descubrirás cómo estudiar y vivir en Canadá de forma inteligente y con un plan claro para alcanzar tus metas
                    </p>
                    <button className="button" aria-label="Quiero estudiar en Canadá" onClick={() => setShowRegisterModal(true)}>¡Regístrate GRATIS Hoy!</button>
                </div>
            </div>


            <section className="locations">
                <div className="container">
                    <div className="title">
                        <h2 className="gradient-title">¡Estudia, trabaja y vive en Canadá!</h2>
                        <p className="lead">
                            Conoce y agenda las próximas fechas para descubrir, junto a nosotros, cómo vivir en Canadá.
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="locations-container">
                        <Locations pauseOnHover={true} speed={30} autoFill={true} />
                    </div>
                    <div className="locations-actions">
                        <p className="small">Horas locales de cada país, Colombia: GTM-5. México: GTM-6</p>
                        <button className="button" onClick={() => setShowRegisterModal(true)}>¡Regístrate Hoy!</button>
                    </div>
                </div>
            </section>

            <section className="info">
                <div style={{ backgroundImage: `url(/assets/images/info.jpg)` }} className="info__container nostatic">
                    <div className="container">
                        <div className="row row-va">
                            <div className="col-12 col-md-6">
                                <div className="left">
                                    <h2>¡Esta no es una expo cualquiera!</h2>
                                    <p>
                                        En esta Expo, <strong style={{ textDecoration: 'underline' }}>totalmente gratuita </strong>encontrarás toda la información necesaria no solo para estudiar en Canadá, sino para hacerlo de manera inteligente, responsable y con un plan claro para alcanzar tus metas.
                                    </p>
                                    <p>
                                        Al pensar en Canadá, muchos imaginan Toronto, Vancouver o Montreal. Aunque son las ciudades más conocidas, existen otros destinos en Canadá con oportunidades increíbles, un costo de vida más accesible y mejores opciones para obtener la residencia permanente.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="right">
                                    <p className="lead"><strong>Explora las oportunidades de estudio, vivienda y trabajo en las provincias emergentes de Canadá:</strong></p>

                                    <ul className="list-unstyled">
                                        <li><span>🤩 </span><span><strong>Bajo costo de vida:</strong> Disfruta de una vida accesible sin comprometer tu calidad de vida.</span></li>
                                        <li><span>🏡 </span><span><strong>Excelentes opciones para residencia permanente:</strong> Accede a programas que facilitan tu camino hacia la residencia en Canadá.</span></li>
                                        <li><span>💼 </span><span><strong>Oportunidades laborales:</strong> Encuentra empleos en sectores clave, con gran demanda de talento.</span></li>
                                        <li><span>🏙️ </span><span><strong>Ciudades en crecimiento:</strong> Vive en zonas que están en plena expansión, con nuevas oportunidades y un entorno dinámico.</span></li>
                                    </ul>
                                    <button className="button" onClick={() => setShowRegisterModal(true)}>¡Regístrate gratis Hoy!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="expo">
                <div className="container">
                    <div className="row expo-row">
                        <div className="col-12 col-sm-6 col-lg-7">

                            <div className="d-flex flex-column expo-info">
                                <h2 className="gradient-title">En la <strong>EXPO PARA CANADÁ</strong>, descubrirás provincias y las oportunidades educativas que te esperan.</h2>
                                <p>
                                    Este es un evento <strong>exclusivo y gratuito</strong> donde tendrás contacto DIRECTO con instituciones educativas de alta calidad, ubicadas en provincias atractivas por su bajo costo de vida y excelentes programas de residencia permanente.
                                </p>
                                <ul className="list-unstyled">
                                    <li><span>✅ </span> Participa en la convocatoria de becas.</li>
                                    <li><span>✅ </span> Consulta a los representantes de las instituciones para resolver todas tus dudas.</li>
                                    <li><span>✅ </span> Asiste a seminarios sobre la vida en Canadá, estudios y mucho más.</li>
                                </ul>
                                <button className="button" onClick={() => setShowRegisterModal(true)}>¡Regístrate Hoy!</button>
                            </div>

                        </div>
                        <div className="col-12 col-sm-6 col-lg-5">
                            <img className="img-fluid expo-img" src="/assets/images/expo.jpg" alt="Expo Canadá" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="flight-banner nostatic" style={{ backgroundImage: `url(/assets/images/banner.jpg)` }}>

                                <figure>
                                    <img src="/assets/images/plane-tickets_9448595.gif" alt="Boleto aéreo a Canadá" />
                                </figure>
                                <div>
                                    <h4>¡Podrás ganar un tiquete aéreo a Canadá!</h4>
                                    <p>
                                        Como parte de tu experiencia en la EXPO, al registrar tu asistencia y participar en las actividades del evento, recibirás automáticamente un boleto para el sorteo de un viaje a Canadá. ¡Tu sueño de conocer este increíble país podría estar más cerca de lo que imaginas!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="timeline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="gradient-title">Tu Camino a Canadá, Paso a Paso</h2>
                                <p className="lead">Sigue estos sencillos pasos y empieza tu viaje hacia una nueva vida en Canadá. Cada etapa te acerca más a cumplir tu meta de estudiar, trabajar y vivir en este increíble país.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="timeline__content">
                                <Timeline />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{ gap: '.5rem' }} className="timeline__actions d-flex flex-column align-items-center">
                                <button className="button" onClick={() => setShowRegisterModal(true)}>¡Quiero vivir o estudiar en Canadá!</button>
                                <p className="small">Regístrate totalmente gratis hoy!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="timeline__footer">
                                <img src="/assets/images/timelineperson.jpg" alt="Timeline Footer" />
                                <div>
                                    <p>
                                        <strong>¡Atención!</strong>
                                        Recuerda que la asesoría migratoria debe ser brindada por un consultor certificado por el gobierno canadiense. En la EXPO, contaremos con un consultor de migración que abordará temas clave y, al final, ofrecerá una sesión de preguntas y respuestas para resolver todas tus dudas.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-ready">
                <div style={{ backgroundImage: `url(/assets/images/getready.jpg)` }} className="get-ready__container nostatic">
                    <div className="container">
                        <div className="content">
                            <span className="pretitle">¿Estás listo para cumplir tus sueños?</span>
                            <h2>¡Prepárate para Vivir y<span> Estudiar en Canadá!</span></h2>
                            <p>
                                Descubre en nuestra EXPO gratuita todo lo que necesitas para estudiar y vivir en Canadá. Tendrás contacto directo con instituciones de alta calidad, ubicadas en provincias de bajo costo de vida y con excelentes opciones de residencia.
                            </p>
                            <p>
                                Conoce los pasos a seguir, resuelve tus dudas con un consultor migratorio certificado y participa en la rifa de un boleto aéreo a Canadá.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs">
                <div className="container containermin">
                    <div className="faqs__content">
                        <div className="title">
                            <span className="pretitle dark">¿Tienes dudas? Aquí las resolveremos</span>
                            <h2 className="gradient-title">Preguntas Frecuentes</h2>
                            <p>
                                Este es un evento exclusivo y gratuito donde tendrás contacto DIRECTO con instituciones educativas de alta calidad, ubicadas en provincias atractivas por su bajo costo de vida y excelentes programas de residencia permanente.
                            </p>
                        </div>
                        <Faqs />
                    </div>
                </div>
            </section >

            <section className="cta">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="gradient-title">¡Reserva tu lugar ahora y da el primer paso hacia tu futuro en Canadá!</h2>
                                <p>
                                    No pierdas la oportunidad de conocer todo lo que necesitas para estudiar, trabajar y vivir en Canadá. Reserva tu lugar y asegúrate de estar un paso más cerca de hacer realidad tus planes.
                                </p>
                                <button className="button" onClick={() => setShowRegisterModal(true)}>¡Quiero vivir o estudiar en Canadá!</button>

                                <ul className="list-unstyled">
                                    <li><a href="https://web.facebook.com/profile.php?id=100072255423424&_rdc=1&_rdr" target="_blank" rel="noreferrer"><span className="fa fa-facebook-official gradient-title"></span></a></li>
                                    <li><a href="https://www.instagram.com/para.canada/" target="_blank" rel="noreferrer"><span className="fa fa-instagram gradient-title"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer nostatic" style={{ backgroundImage: `url(/assets/images/footer.jpg)` }}>
                <div className="container">
                    <div className="row footer-contact">
                        <div className="col-12">
                            <h2 className="gradient-title">¡Contáctanos!</h2>
                            <p>
                                Si tienes alguna pregunta, no dudes en contactarnos a <a target="_blank" rel="noreferrer" href="mailto:info@para-canada.com">info@para-canada.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="row footer-copy">
                        <div className="col-12">
                            <p>
                                &copy; 2024. Todos los derechos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
}

export default Home; 